$side-paddings: (300px: 16px, 400px: 32px);
$side-paddings-negative: (300px: -16px, 400px: -32px);
$header-heights: (320px: 80px, 599px: 100px, 600px: 125px, 1200px: 145px);
$site-max-width: 1300px;

:root {
    --brand-blue: rgba(20,51,79,1);
    --brand-green: rgba(17,89,78,1);
    --brand-red: rgba(124,42,83,1);
    --brand-magenta: rgba(225,9,126,1);

    // Colors
    // -------------------------------------------------------
    --gray-100: #f7fafc;
    --gray-200: #edf2f7;
    --gray-300: #e2e8f0;
    --gray-400: #cbd5e0;
    --gray-500: #a0aec0;
    --gray-600: #718096;
    --gray-700: #4a5568;
    --gray-800: #2d3748;
    --gray-900: #1a202c;
    --red-100: #fff5f5;
    --red-200: #fed7d7;
    --red-300: #feb2b2;
    --red-400: #fc8181;
    --red-500: #f56565;
    --red-600: #e53e3e;
    --red-700: #c53030;
    --red-800: #9b2c2c;
    --red-900: #742a2a;
    --green-100: #f0fff4;
    --green-200: #c6f6d5;
    --green-300: #9ae6b4;
    --green-400: #68d391;
    --green-500: #48bb78;
    --green-600: #38a169;
    --green-700: #2f855a;
    --green-800: #276749;
    --green-900: #22543d;
    --blue-100: #EBF6FF;
    --blue-200: #BEDDF8;
    --blue-300: #90C5F4;
    --blue-400: #63ADED;
    --blue-500: #1b84df;
    --blue-600: #1D69AA;
    --blue-700: #1B5180;
    --blue-800: var(--brand-blue);
    --blue-900: #04111D;
    --cyan-100: #E4F6FE;
    --cyan-200: #C2ECFE;
    --cyan-300: #86DBFF;
    --cyan-400: #43C7FF;
    --cyan-500: #009FE3;
    --cyan-600: #0885BB;
    --cyan-700: #0A5D81;
    --cyan-800: #063C53;
    --cyan-900: #06161D;

    // Shadows
    // -------------------------------------------------------
    --shadow-xs: 0 0 0 1px rgba(0, 0, 0, 0.05);
    --shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    --shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    --shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    --shadow-2xl: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}
