.mediaSliderItem {
    width: 90%;
    margin-right: 16px;

    img,
    iframe {
        border-radius: 16px;
        transition: opacity 0.3s;
        border: 0;
    }

    @include breakpoint(1200px) {
        width: 50%;
    }
}

.mediaSliderItem:not(.is-selected) {
    img,
    video {
        opacity: 0.3;
    }

    figcaption {
        opacity: 0;
        transition-delay: 0s;
    }
}
