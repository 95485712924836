// =======================================================
// Slogan Animation
// =======================================================
@mixin mobileSloganAnimation($translateX, $translateY, $scale) {
    0% {
        opacity: 0;
        transform: translateX(calc(50vw - 170px)) translateX(#{$translateX}) translateY(#{$translateY}) scale3d(#{$scale - 0.3}, #{$scale - 0.3}, 1);
    }
    15% {
        opacity: 1;
        transform: translateX(calc(50vw - 170px)) translateX(#{$translateX}) translateY(#{$translateY}) scale3d(#{$scale + 0.1}, #{$scale + 0.1}, 1);
    }
    30%, 80% {
        opacity: 1;
        transform: translateX(calc(50vw - 170px)) translateX(#{$translateX}) translateY(#{$translateY}) scale3d(#{$scale}, #{$scale}, 1);
    }
    100% {
        opacity: 1;
        transform: translateX(0) translateY(0) scale3d(1, 1, 1);
    }
}

@mixin desktopSloganAnimation($translateX, $translateY, $scale) {
    0% {
        opacity: 0;
        transform: translateX(#{$translateX}) translateY(#{$translateY + 150px}) scale3d(#{$scale - 0.3}, #{$scale - 0.3}, 1);
    }
    15% {
        opacity: 1;
        transform: translateX(#{$translateX}) translateY(#{$translateY + 150px}) scale3d(#{$scale + 0.1}, #{$scale + 0.1}, 1);
    }
    30%, 80% {
        opacity: 1;
        transform: translateX(#{$translateX}) translateY(#{$translateY + 150px}) scale3d(#{$scale}, #{$scale}, 1);
    }
    100% {
        opacity: 1;
        transform: translateX(0) translateY(0) scale3d(1, 1, 1);
    }
}

[data-desktop-header="logo"] svg,
[data-mobile-header="logo"] svg {
    #logo-frame {
        transform-origin: center 20px;
    }

    #logo-top-center {
        transform-origin: center 3.85px;
    }

    #logo-top-circle {
        transform-origin: center 3.85px;
    }

    #logo-top-left {
        transform-origin: 9.64px 3.85px;
    }

    #logo-top-right {
        transform-origin: 21.36px 3.85px;
    }

    #logo-bar-1 {
        transform-origin: 5.17px 42.8px;
    }

    #logo-bar-2 {
        transform-origin: 9.3px 44.8px;
    }

    #logo-bar-3 {
        transform-origin: 13.43px 45.6px;
    }

    #logo-bar-4 {
        transform-origin: 17.57px 45.8px;
    }

    #logo-bar-5 {
        transform-origin: 21.7px 44.4px;
    }

    #logo-bar-6 {
        transform-origin: 25.83px 42.9px;
    }
}

// =======================================================
// Start Animation
// -------------------------------------------------------
// 1. Logo aufbauen
// 2. Navigation einfaden
// 3. Hero Inhalte einfaden
// 4. Sekundär Navigation einblenden
// =======================================================
.show-start-animation {
    // 1. Logo aufbauen
    [data-desktop-header="logo"] svg,
    [data-mobile-header="logo"] svg {
        polygon,
        path {
            animation-fill-mode: both;
            animation-duration: 1s;
            animation-iteration-count: 1;
            animation-timing-function: ease-out;
        }

        #logo-frame {
            animation-name: logoFrame;
        }

        #logo-top-center {
            animation-name: logoScaleHorizontally;
            animation-delay: 0.2s;
        }

        #logo-top-circle {
            animation-name: logoScale;
            animation-delay: 0.4s;
        }

        #logo-top-left {
            animation-name: logoScaleHorizontally;
            animation-delay: 0.8s;
        }

        #logo-top-right {
            animation-name: logoScaleHorizontally;
            animation-delay: 0.8s;
        }

        #logo-bar-1 {
            animation-name: logoScaleVertically;
            animation-delay: 1.2s;
        }

        #logo-bar-2 {
            animation-name: logoScaleVertically;
            animation-delay: 1.3s;
        }

        #logo-bar-3 {
            animation-name: logoScaleVertically;
            animation-delay: 1.4s;
        }

        #logo-bar-4 {
            animation-name: logoScaleVertically;
            animation-delay: 1.5s;
        }

        #logo-bar-5 {
            animation-name: logoScaleVertically;
            animation-delay: 1.6s;
        }

        #logo-bar-6 {
            animation-name: logoScaleVertically;
            animation-delay: 1.7s;
        }
    }

    // 1.2 Logo Titel einfaden
    [data-desktop-header="logo-title"] {
        animation-fill-mode: both;
        animation-duration: 1.5s;
        animation-iteration-count: 1;
        animation-timing-function: ease-out;
        animation-name: fadeIn;
        animation-delay: 1s;
    }

    // 2. Navigation einfaden
    [data-desktop-header="link"],
    [data-mobile-header="toggle"] .text,
    [data-mobile-header="toggle"] .burger > div {
        animation-fill-mode: both;
        animation-duration: 1s;
        animation-iteration-count: 1;
        animation-timing-function: ease-out;
    }

    [data-desktop-header="links"]:first-child {
        [data-desktop-header="link"]:first-child {
            animation-name: headerLinkFadeInToLeft;
            animation-delay: 1.25s;
        }

        [data-desktop-header="link"]:last-child {
            animation-name: headerLinkFadeInToLeft;
            animation-delay: 1.1s;
        }
    }

    [data-desktop-header="links"]:last-child {
        [data-desktop-header="link"]:first-child {
            animation-name: headerLinkFadeInToRight;
            animation-delay: 1.1s;
        }

        [data-desktop-header="link"]:last-child {
            animation-name: headerLinkFadeInToRight;
            animation-delay: 1.25s;
        }
    }

    [data-mobile-header="toggle"] .text {
        animation-name: headerLinkFadeInToLeft;
        animation-delay: 1.1s;
    }

    [data-mobile-header="toggle"] .burger > div {
        animation-name: logoScaleHorizontally;

        &:nth-child(1) {
            animation-delay: 1.1s;
        }

        &:nth-child(2) {
            animation-delay: 1.2s;
        }

        &:nth-child(3) {
            animation-delay: 1.3s;
        }
    }

    // 3. Hero Inhalte einfaden
    [data-hero-pretitle],
    [data-hero-title],
    [data-hero-intro],
    [data-hero-buttons],
    [data-desktop-header="languages"],
    [data-desktop-header="secondary"] {
        animation-fill-mode: both;
        animation-duration: 1s;
        animation-iteration-count: 1;
        animation-timing-function: ease-out;
        animation-name: headerLinkFadeInToRight;
    }

    [data-hero-slogan] {
        animation-fill-mode: both;
        animation-duration: 2.5s;
        animation-iteration-count: 1;
        animation-timing-function: ease-in-out;
    }

    [data-hero-slogan="connecting"] {
        animation-name: mobileSloganConnecting;
        animation-delay: 1s;

        @include breakpoint(600px) {
            animation-name: desktopSloganConnecting;
        }
    }

    @keyframes mobileSloganConnecting {
        @include mobileSloganAnimation(75.5px, 13.5px, 2.31);
    }

    @keyframes desktopSloganConnecting {
        @include desktopSloganAnimation(70.5px, -25.5px, 1.93);
    }

    [data-hero-slogan="minds"] {
        animation-name: mobileSloganMinds;
        animation-delay: 1.15s;

        @include breakpoint(600px) {
            animation-name: desktopSloganMinds;
        }
    }

    @keyframes mobileSloganMinds {
        @include mobileSloganAnimation(-28.5px, 60px, 3.08);
    }

    @keyframes desktopSloganMinds {
        @include desktopSloganAnimation(204px, -23.5px, 2.24);
    }

    [data-hero-slogan="to"] {
        animation-name: mobileSloganTo;
        animation-delay: 1.3s;

        @include breakpoint(600px) {
            animation-name: desktopSloganTo;
        }
    }

    @keyframes mobileSloganTo {
        @include mobileSloganAnimation(112px, 82.5px, 1.38);
    }

    @keyframes desktopSloganTo {
        @include desktopSloganAnimation(-161.5px, 16.5px, 0.97);
    }

    [data-hero-slogan="foster"] {
        animation-name: mobileSloganFoster;
        animation-delay: 1.45s;

        @include breakpoint(600px) {
            animation-name: desktopSloganFoster;
        }
    }

    @keyframes mobileSloganFoster {
        @include mobileSloganAnimation(167.5px, 89px, 2.525);
    }

    @keyframes desktopSloganFoster {
        @include desktopSloganAnimation(-111.1px, 25px, 1.9875);
    }

    [data-hero-slogan="knowledge"] {
        animation-name: mobileSloganKnowledge;
        animation-delay: 1.6s;

        @include breakpoint(600px) {
            animation-name: desktopSloganKnowledge;
        }
    }

    @keyframes mobileSloganKnowledge {
        @include mobileSloganAnimation(-14px, 136px, 2.036);
    }

    @keyframes desktopSloganKnowledge {
        @include desktopSloganAnimation(-32px, 32.5px, 1.33);
    }

    [data-hero-pretitle] {
        animation-delay: 1.6s;
    }

    [data-hero-title] {
        animation-delay: 3.8s;
        animation-name: scaleFadeInBouncy;
        animation-duration: 0.7s;
    }

    [data-hero-intro] {
        animation-delay: 4s;
        animation-name: scaleFadeInBouncy;
        animation-duration: 0.7s;
    }

    [data-hero-buttons] {
        animation-delay: 4.2s;
        animation-name: scaleFadeInBouncy;
        animation-duration: 0.7s;
    }

    // 4. Sekundär Navigation einblenden
    [data-desktop-header="languages"] {
        animation-delay: 2s;
    }

    [data-desktop-header="secondary"] {
        animation-name: headerLinkFadeInToLeft;
        animation-delay: 2s;
    }
}


@keyframes logoFrame {
    0% {
        opacity: 0;
        transform: scale(0.8);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes logoScale {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes logoScaleHorizontally {
    0% {
        transform: scaleX(0);
    }
    100% {
        transform: scaleX(1);
    }
}

@keyframes logoScaleVertically {
    0% {
        transform: scaleY(0);
    }
    100% {
        transform: scaleY(1);
    }
}

@keyframes headerLinkFadeInToLeft {
    0% {
        opacity: 0;
        transform: translateX(5px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes headerLinkFadeInToRight {
    0% {
        opacity: 0;
        transform: translateX(-5px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes scaleFadeIn {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes scaleFadeInBouncy {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    75% {
        transform: scale(1.03);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
