$publicationCardHorizontalBreakpoints: 520px 619px;

.cards {
    display: grid;
    row-gap: 20px;
    column-gap: 2%;
    grid-template-columns: 100%;

    &.huge {
        max-width: 900px;
        margin-right: auto;
        margin-left: auto;
    }

    &.big {
        @include breakpoint(950px) {
            grid-template-columns: 49% 49%;
        }
    }
}


// -------------------------------------------------------
// Einzelne Karte
// -------------------------------------------------------
.card {
    position: relative;
    display: block;
    border-radius: 8px;
    box-shadow: var(--shadow);
    background-color: #fff;
    user-select: none;
}

.card.big-shadow {
    box-shadow: var(--shadow-xl);
}

.card.event-card {
    box-shadow: none; // Nur weil in der Blade Datei der Schatten per Tailwind gesetzt wird
}

a.card {
    transition: box-shadow 0.15s, transform 0.15s;
    color: inherit;
    text-decoration: none;
    cursor: pointer;

    &:hover,
    &:focus {
        box-shadow: var(--shadow-lg);
        transform: translate3d(0, -1px, 0);
    }

    // Während eines Slider gezogen wird, soll der Link nicht ausgelöst werden
    .is-draggable.dragging & {
        pointer-events: none;
    }
}

.card.tiny {
    display: grid;
    grid-template-columns: 120px 1fr;
}

@include breakpoint(620px) {
    .cards.big .event-card,
    .cards.huge .event-card {
        display: grid;
        grid-template-columns: 2fr 3fr;
    }

    .cards.huge .event-card {
        min-height: 300px;
    }
}


// Thumbnail
// -------------------------------------------------------
.card-thumbnail {
    position: relative;
    height: 220px;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    background-color: var(--gray-300);

    img {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .card.tiny & {
        height: 100%;
        border-radius: 8px 0 0 8px;
    }

    @include breakpoint(620px) {
        .cards.big .event-card &,
        .cards.huge .event-card & {
            height: 100%;
            border-radius: 8px 0 0 8px;
        }
    }
}

.card-thumbnailBadge {
    position: absolute;
    z-index: 1;
    top: 8px;
    right: 8px;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.95);
    font-size: 12px;
    font-weight: 600;
    color: var(--cyan-500);
    letter-spacing: 0.86px;
    text-transform: uppercase;
}

// Bereich mit Text
// -------------------------------------------------------
.card-inner {
    @include poly-fluid-sizing("padding", (400px: 16px, 800px: 24px));
    overflow: hidden;
}

.card-header {
    margin-bottom: 16px;
}

.card-preMeta {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    .year {
        color: var(--gray-500);
    }
}

.card-title {
    @include h3;

    &.h2 {
        @include h2;
    }

    &.centered,
    &.center {
        @include breakpoint(400px) {
            text-align: center;
        }
    }
}

.card-meta {
    margin-top: 8px;

    .metaItem:not(:first-child) {
        margin-top: 4px;
    }
}

// Close Button (bei Modals)
.card-close {
    position: absolute;
    top: 8px;
    right: 8px;

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        color: var(--gray-500);
        appearance: none;
        border: 0;
        outline: 0;
        box-shadow: none;
        background: none;
        cursor: pointer;
        transition: color .15s;

        &:hover,
        &:focus {
            color: var(--gray-800);
        }
    }
}

// Content
// -------------------------------------------------------
.card-content {
    @include poly-fluid-sizing("font-size", (400px: 14px, 800px: 16px));
    line-height: 1.5;
}

.card-content:not(:first-child) {
    margin-top: 24px;
}

.card-footerShare {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 32px;

    .card-footerShare-headline {
        @include h4;
        color: var(--gray-500);
        margin-bottom: 6px;

        span {
            color: var(--cyan-500);
        }
    }
}

.card-footer {
    margin-top: 8px;
    margin-bottom: -8px;
}

// -------------------------------------------------------
// Card im Modal
// -------------------------------------------------------
.card--modal {
    .card-content:not(:first-child) {
        margin-top: 32px;
    }
}
