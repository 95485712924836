// Box sizing rules
*,
*::before,
*::after {
    box-sizing: border-box;
}

// Remove default padding
ul[class],
ol[class] {
    padding: 0;
}

// Remove default margin
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

// Set core body defaults
body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    font-family: "Lato", "Helvetica Neue", "Helvetica", sans-serif;
}

// Remove list styles on ul, ol elements with a class attribute
ul[class],
ol[class] {
    list-style: none;
}

// Make images easier to work with
img {
    max-width: 100%;
    display: block;
}

// Inherit fonts for inputs and buttons
input,
button,
textarea,
select {
    font: inherit;
}

input[type="search"] {
    appearance: none;
}

// Remove all animations and transitions for people that prefer not to see them
@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}
