// .modalContainer
//   .modal
.modalWrapperOuter {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    overflow-y: auto;
}

.modalWrapperInner {
    display: flex;
    align-items: flex-end;
    justify-items: center;
    min-height: 100vh;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 80px;
    text-align: center;

    @include breakpoint(640px) {
        display: block;
        padding: 0;
    }
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    transition: opacity 0.3s ease-in-out;
}

.modalSpacer {
    display: none;

    @include breakpoint(640px) {
        display: block;
        vertical-align: middle;
        height: 100vh;
    }
}

.modal {
    display: inline-block;
    vertical-align: bottom;
    text-align: left;
    overflow: hidden;

    @include breakpoint(640px) {
        margin-top: 32px;
        margin-bottom: 32px;
        vertical-align: middle;
        width: 100%;
        max-width: 500px;
    }
}
