// Headings
// -------------------------------------------------------
// H2
@mixin h2 {
    @include poly-fluid-sizing("font-size", (400px: 24px, 1000px: 28px));
    font-weight: 700;
    line-height: 1.2;
}

h2,
.h2 {
    @include h2;
}

// H3
@mixin h3 {
    @include poly-fluid-sizing("font-size", (400px: 19px, 1000px: 22px));
    font-weight: 700;
    line-height: 1.25;
}

h3,
.h3 {
    @include h3;
}

// H4
@mixin h4 {
    font-size: 17px;
    font-weight: 700;
    line-height: 1.5;
}

h4,
.h4 {
    @include h4;
}

// Body Text
// -------------------------------------------------------
// Large Text (Hero Intro)
@mixin text-large {
    @include poly-fluid-sizing("font-size", (400px: 18px, 1000px: 22px));
    line-height: 1.6;
}

.text-large {
    @include text-large;
}

// Medium Text (Features)
@mixin text-medium {
    @include poly-fluid-sizing("font-size", (400px: 18px, 1000px: 20px));
    line-height: 1.5;
}

.text-medium {
    @include text-medium;
}

// p,
// ul:not([class]),
// ol:not([class]),
// .text-default {
//     @include poly-fluid-sizing("font-size", (400px: 16px, 1000px: 18px));
//     line-height: 1.5;
// }
