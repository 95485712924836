// =======================================================
// Tabs
// =======================================================
.pressKit-tabs {
    display: flex;
    justify-content: center;

    &:first-child {
        @include poly-fluid-sizing("padding-top", (400px: 40px, 800px: 60px, 1200px: 100px));
    }
}

.pressKit-tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 20px;
    background: transparent;
    border-radius: 6px;
    border: none;
    outline: none;
    appearance: none;
    color: var(--gray-500);
    transition: all 0.15s;
    cursor: pointer;

    &:hover,
    &:focus {
        background: var(--gray-100);
        color: var(--gray-600);
    }

    &.is-active {
        box-shadow: 2px 7px 20px 0 rgba(200,200,200,0.31);
        color: var(--gray-900);
        background: #fff;
        cursor: default;
    }
}

.pressKit-tab-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
}

.pressKit-tab-label {
    padding-top: 14px;
    font-weight: 700;
}

@include breakpoint(500px) {
    .pressKit-tab {
        min-width: 140px;
        margin: 0 10px;
        padding: 32px;

        &.is-active {
            box-shadow: 4px 15px 41px 0 rgba(200,200,200,0.31);
        }
    }
}


// =======================================================
// Logos
// =======================================================
.pressKit-logos {
    width: 100%;

    @include breakpoint(600px) {
        display: grid;
        align-items: stretch;
        grid-template-columns: 1fr 1fr;
    }

    @include breakpoint(900px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

.pressKit-logo-pack {
    display: grid;
    grid-template-areas: "logo""title""info""download";
    row-gap: 1em;
    column-gap: 24px;
    justify-items: center;
    padding: 24px;
    text-align: center;
}

.pressKit-logo {
    grid-area: logo;
    max-width: 230px;
    margin-bottom: 16px;
}

.pressKit-color-title {
    grid-area: title;
    display: flex;
    align-items: center;
    justify-self: center;
}

.pressKit-color-tint {
    flex-shrink: 0;
    width: 1em;
    height: 1em;
    margin-right: 0.3em;
    border-radius: 6px;
}

.pressKit-color-info {
    grid-area: info;
    color: var(--gray-800);
}

.pressKit-logo-download {
    grid-area: download;
    color: var(--gray-800);

    a {
        color: var(--cyan-500);
        text-decoration: none;
        transition: color 0.15s;

        &:hover,
        &:focus {
            color: var(--cyan-700);
        }
    }
}
