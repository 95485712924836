@import "../../node_modules/breakpoint-sass/stylesheets/breakpoint";
@import "../../node_modules/poly-fluid-sizing/poly-fluid-sizing";
@import "../../node_modules/flickity/dist/flickity.css";
$aos-distance: 10px;
@import "aos/src/sass/aos.scss";

@import "settings/variables";
@import "settings/fonts";
@import "settings/mixins";

@import "site/typography";
@import "site/reset";

@import "components/meta-item";
@import "components/cards";
@import "components/researcher-card";
@import "components/modal";
@import "components/slider.cards";
@import "components/slider.media";
@import "components/slider.partner";
@import "components/inputs";
@import "components/press-kit";
@import "components/start-animation";
@import "components/researcher-map";

// =======================================================
// INBOX
// =======================================================
// Flickity
.flickity-button {
    background: transparent;
    color: var(--gray-500);
    transition: all 0.15s;
    @include poly-fluid-sizing("width", $side-paddings);

    &:hover,
    &:focus {
        background: transparent;
        color: var(--gray-700);
    }

    &:disabled {
        opacity: 0;
    }

    &.previous {
        margin-left: 5px;
        @include poly-fluid-sizing("left", $side-paddings-negative);
    }

    &.next {
        margin-right: 5px;
        @include poly-fluid-sizing("right", $side-paddings-negative);
    }

    @include breakpoint(1400px) {
        &.previous {
            margin-left: -10px;
        }

        &.next {
            margin-right: -10px;
        }
    }
}

// Dots
.flickity-page-dots {
    bottom: -32px;

    .dot {
        background-color: var(--gray-600);
    }

    .dot.is-selected {
        background-color: var(--cyan-500);
    }
}

.rotate-45 {
    transform: rotate(45deg);
}

.origin-top-right {
    transform-origin: top right;
}

.event-card {
    container-type: inline-size;
}

.save-the-date {
    position: absolute;
    top: 140px;
    right: -50px;
    padding: 0 40px;
    transform: rotate(45deg);
    transform-origin: top right;
    display: flex;
    justify-content: center;
    background-color: var(--cyan-500);
}

.save-the-date + h3 {
    padding-right: 64px;
}

@container (min-width: 750px) {
    .save-the-date + h3 {
        padding-right: 0;
    }
}
