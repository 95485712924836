.metaItem {
    --text: var(--gray-700);
    --text-hover: var(--cyan-500);
    --icon: var(--gray-500);
    display: flex;
    align-items: flex-start;
    font-size: 16px;
    color: var(--text);

    a {
        color: inherit;
        text-decoration: none;
        cursor: default;

        &:not([href=""]):hover {
            color: var(--text-hover);
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

.metaItem.category {
    --text: var(--cyan-500);
    --icon: var(--cyan-500);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.03em;
}

.metaItem.highlight {
    --text: var(--cyan-500);
    --icon: var(--cyan-300);
}

.metaItem .icon {
    flex-shrink: 0;
    display: flex;
    flex-direction: row-reverse;
    margin-right: 6px;
    padding-top: 2px;
    color: var(--icon);
    margin-left: -2px;

    svg,
    img {
        width: 20px;
        height: 20px;
        border: 2px solid #fff;

        &:not(:first-child) {
            margin-right: -10px;
            border-radius: 50%;
        }
    }

    img {
        border-radius: 50%;
    }
}

.metaItem svg {
    display: block;
}

.metaItem .icon img {
    border-radius: 50%;
    background-color: var(--gray-500);
}

.metaItem .text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .card.tiny & {
        overflow: auto;
        white-space: normal;
    }
}
