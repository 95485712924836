.researchMapMarker {
    animation-name: markerAnimation;
    animation-duration: 1s;
    animation-fill-mode: backwards;
    animation-timing-function: ease-in-out;
}

@for $x from 1 through 50 {
    .researchMapMarker:nth-child(#{$x}) {
        animation-delay: 300ms * ($x - 1);
    }
}

@keyframes markerAnimation {
    0% {
        transform: translate3d(-50%, -50%, 0) scale(6);
        filter: blur(1px);
        opacity: 0;
    }
    100% {
        transform: translate3d(-50%, -50%, 0) scale(1);
        filter: blur(0);
        opacity: 1;
    }
}
