@import "inputs.checkbox";

.form-field:not(:first-child),
.form-fields:not(:first-child),
.form-actions:not(:first-child),
.form-action:not(:first-child),
.form-success:not(:first-child) {
    margin-top: 1em;
}

.form-fields {
    display: flex;

    .form-field {
        flex-grow: 1;
    }

    .form-actions {
        margin-top: 0;
        padding-left: 8px;
        padding-top: 25px;
    }
}

.form-success {
    font-size: 16px;
    color: var(--green-600);

    p {
        font-size: inherit;
        color: inherit;
    }
}

.field-label {
    display: block;
    margin: 0;
    padding: 0 0 6px 6px;
    line-height: 19px;
    font-size: 14px;
    color: var(--gray-700);
    letter-spacing: 0.4px;
    text-transform: uppercase;

    small {
        padding-left: 4px;
        text-transform: none;
        font-size: 12px;
        color: var(--gray-500);
        letter-spacing: initial;
    }
}

.field-help {
    display: block;
    padding-top: 10px;
    padding-left: 6px;
    font-size: 17px;
    color: var(--gray-600);
    line-height: 23px;

    a {
        color: var(--cyan-500);
        font-weight: 700;
        text-decoration: none;
        transition: color 0.15s;

        &:hover,
        &:focus {
            color: var(--cyan-600);
        }
    }
}

.form-control {
    --background-color: #fff;
    --border-color: var(--gray-400);
    --border-color-hover: var(--cyan-400);
    --border-color-focus: var(--cyan-500);
    --border-color-error: var(--red-400);
    --border-color-success: var(--green-600);
    --text-color: var(--gray-900);
    --placeholder-color: var(--gray-700);
    position: relative;
    display: block;
    width: 100%;
    padding: 12px 16px;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    background: var(--background-color);
    font-size: 18px;
    line-height: 1.3;
    box-shadow: none;
    outline: none;
    color: var(--text-color);

    &::placeholder {
        color: var(--placeholder-color);
    }

    &:hover {
        border-color: var(--border-color-hover);
    }

    &:focus {
        border-color: var(--border-color-focus);
    }

    .has-error & {
        border-color: var(--border-color-error);
    }

    .is-correct & {
        border-color: var(--border-color-success);
    }
}

.form-control[type="email"] {
    padding-right: 56px;
    background-image: url("/assets/svg/mail-gray.svg");
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: center right 16px;
}

.form-control[type="search"] {
    padding-left: 52px;
    background-image: url("/assets/svg/search-gray.svg");
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: 16px center;
}

.field-error {
    --text-color: var(--red-600);
    display: block;
    padding: 4px 8px;
    color: var(--text-color);
}

label.checkbox {
    display: flex;
    align-items: flex-start;
    cursor: pointer;

    input {
        flex-shrink: 0;
    }

    span {
        padding-left: 8px;
        margin-top: -1px;

        a {
            color: var(--cyan-500);
        }
    }
}

textarea {
    resize: vertical;
    min-height: 100px;
}
