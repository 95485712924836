$bp-researcher-card: 680px;

.researcherCard {
    @include breakpoint($bp-researcher-card) {
        display: grid;
        grid-template-columns: 260px 1fr;
        grid-template-rows: auto auto auto 1fr;
        align-items: flex-start;
        column-gap: 32px;
    }
}

.researcherCard-photo {
    width: 180px;
    margin: 0 auto;

    img {
        border-radius: 50%;
    }

    @include breakpoint($bp-researcher-card) {
        grid-column: 1 / span 1;
        grid-row: 1 / span 2;
    }
}

.researcherCard-social {
    margin: 16px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    a {
        padding: 6px;
        width: 32px;
        height: 32px;
        margin: 0 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--gray-500);
        transition: color 0.15s;

        &.ssrn {
            width: 48px;
            height: 30px;
        }

        &:hover,
        &:focus {
            color: var(--cyan-500);
        }

        @include breakpoint($bp-researcher-card) {
            grid-column: 1 / span 1;
            grid-row: 3 / span 1;
        }
    }

    svg {
        width: 100%;
        height: auto;
        display: block;
    }
}

.researcherCard-header {
    margin-top: 24px;
    text-align: center;

    .h3 {
        margin-bottom: 0.2em;
    }

    div {
        @include text-medium;
    }

    @include breakpoint($bp-researcher-card) {
        text-align: left;
        grid-column: 2 / span 1;
        grid-row: 1 / span 1;
    }
}

.reseacherCard-cta {
    margin: 24px auto 0;
    max-width: 440px;
    grid-column-start: 1;
    grid-row-start: 4;

    @include breakpoint($bp-researcher-card) {
        grid-column: 1 / 1;
        grid-row: 4 / span 1;
    }
}

.researcherCard-cta-buttons {
    margin: -4px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .button {
        flex-grow: 1;
        justify-content: center;
        margin: 4px;
    }
}

.researcherCard-content {
    margin: 24px auto 0;
    max-width: 600px;

    p + p {
        margin-top: 0.5em;
    }

    @include breakpoint($bp-researcher-card) {
        grid-column: 2 / 2;
        grid-row: 2 / span 3;
    }
}

.researcherCard-groups {
    margin-top: 16px;
    text-align: center;

    strong {
        display: block;
    }

    span {
        display: inline-block;
        padding: 6px 12px;
        margin-right: 4px;
        margin-top: 6px;
        border-radius: 6px;
        background-color: var(--cyan-100);
        color: var(--cyan-500);
        font-weight: 700;
        font-size: 15px;
    }

    @include breakpoint($bp-researcher-card) {
        text-align: left;
    }
}
