.partnerSlider {
    @include poly-fluid-sizing("margin-top", (400px: 20px, 800px: 80px));
    @include poly-fluid-sizing("padding-bottom", (400px: 0, 800px: 20px, 1200px: 60px));
}

.partnerSlider-item {
    width: 90%;
    padding: 0 5px 40px;
    margin-right: 10px;
    opacity: .5;
    transition: opacity 0.15s;

    &.is-selected {
        opacity: 1;
    }

    @include breakpoint(900px) {
        width: 40%;
    }
}

// Name + Logo
// -------------------------------------------------------
.partnerSlider-logo {
    float: right;
    margin: 0 0 32px 32px;
}


// =======================================================
// Buttons
// =======================================================
.partnerSlider .flickity-button {
    color: var(--gray-500);
    background: rgba(255, 255, 255, 0.8);
    margin: 0 !important;

    &.previous {
        left: 0;
    }

    &.next {
        right: 0;
    }
}
