@font-face {
    font-family: "Lato";
    src: url("/assets/fonts/Lato-Regular.woff2") format("woff2"),
         url("/assets/fonts/Lato-Regular.woff") format("woff"),
         url("/assets/fonts/Lato-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src: url("/assets/fonts/Lato-Bold.woff2") format("woff2"),
         url("/assets/fonts/Lato-Bold.woff") format("woff"),
         url("/assets/fonts/Lato-Bold.ttf") format("truetype");
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src: url("/assets/fonts/Lato-Italic.woff2") format("woff2"),
         url("/assets/fonts/Lato-Italic.woff") format("woff"),
         url("/assets/fonts/Lato-Italic.ttf") format("truetype");
    font-style: italic;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src: url("/assets/fonts/Lato-Black.woff2") format("woff2"),
         url("/assets/fonts/Lato-Black.woff") format("woff"),
         url("/assets/fonts/Lato-Black.ttf") format("truetype");
    font-style: normal;
    font-weight: 900;
    font-display: swap;
}

// @font-face {
//     font-family: "Lato";
//     src: url("/assets/fonts/Lato-Light.woff2") format("woff2"),
//          url("/assets/fonts/Lato-Light.woff") format("woff"),
//          url("/assets/fonts/Lato-Light.ttf") format("truetype");
//     font-style: normal;
//     font-weight: 300;
//     font-display: swap;
// }
