.cardsSliderItem {
    // Sorgt dafür, dass die Cards im Slider alle dieselbe Höhe haben
    min-height: 100%;
    display: flex;
    flex-direction: column;
    // Platz für den Schatten
    padding: 10px 10px 15px;

    .card {
        // min-height: 100%;
        flex-grow: 1;
    }
}

.cardsSliderItem.small {
    width: 100%;

    @include breakpoint(620px) {
        width: 50%;
    }

    @include breakpoint(950px) {
        width: 33.333%;
    }

    @include breakpoint(1200px) {
        width: 25%;
    }
}
